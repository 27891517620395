import React, { useState } from "react";
import { Container, Row, Col, Button, Modal, Form } from 'react-bootstrap';

const LoginForm = ({ onSubmit }) => {

    return (
        <div className="_button-wrapper _full_width">

            <p className="text-center">
                Dapatkan Bonus Spesial Ini, Jika Daftar VIP Waiting List Sebelum 28 Februari
                - 3 Video Series dan Disc Up To 60% Early Bird Attraction Mastery
            </p>

            <Form method="POST" action="https://mrjose.activehosted.com/proc.php">
            <input type="hidden" name="u" value="77" />
            <input type="hidden" name="f" value="77" />
            <input type="hidden" name="s" />
            <input type="hidden" name="c" value="0" />
            <input type="hidden" name="m" value="0" />
            <input type="hidden" name="act" value="sub" />
            <input type="hidden" name="v" value="2" />

            <Form.Group controlId="formBasicPassword">
                <Form.Label>Nama Lengkap : </Form.Label>
                <Form.Control type="text" name="fullname" placeholder="Wajib" required/>
            </Form.Group>

            <Form.Group controlId="formBasicEmail">
                <Form.Label>Email Aktif:</Form.Label>
                <Form.Control type="email" name="email" placeholder="Wajib" required/>
            </Form.Group>

            <Button className="__color-outline" type="submit" size="lg" block >
                Submit
            </Button>
            </Form>

        </div>
    );
};


export default function Three(){

    const [show, setShow] = useState(false);
  
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
  
    const onLoginFormSubmit = (e) => {
      e.preventDefault();
      handleClose();
    };

    return (
        <>

            <Container fluid className="__bg-image">
                <Container>
                    <Row className="align-items-center __height-middle fade-in">
                        <Col lg={8} md={8} sm={8} xs={12}>
                            <div className="__text-desc">
                                <h2 className="__text-headline text-white">
                                    Join VIP Waiting List Attraction Mastery 
                                    Sebelum 28 Februari 2021!
                                </h2>
                                <p className="text-left text-white">
                                    Untuk dapatkan BONUS 3 video series Attraction Mastery <br/>
                                    dan Diskon 60% Tiket VIP Early Bird Attraction Mastery
                                </p>
                                <div className="__flex-mobile">
                                    <Button variant="primary" size="lg" onClick={handleShow}>
                                        JOIN WAITING LIST
                                    </Button>{' '}
                                </div>
                            </div>
                        </Col>
                        <Col lg={4} md={4} xs={12}>
                            <div className="__image-smartphone">
                                <img 

                                    src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovetraining.id/attraction-mastery/smartphone.png"
                                    alt="smartphone"
                                    className="img img-fluid lazyload"

                                />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Container>


            <Modal 
            
            show={show} onHide={handleClose} 
            size="md"
            centered
            >
                <Modal.Header closeButton>
                    <Modal.Title className="__text-wl-title">
                        Join Waiting List
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="__modal-body-bg">
                    <LoginForm onSubmit={onLoginFormSubmit} />
                </Modal.Body>
                
            </Modal>

        </>
    )
}